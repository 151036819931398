import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModAnimation from "../../../components/Chronology/Modules/ModAnimation";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModDotList from "../../../components/Chronology/Modules/ModDotList";

export const frontmatter = {
  title: "Día 88",
  week: "Semana 13",
  day: "09",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-09",
  path: "/cronologia/semana-13#dia-09-jun/",
};

const Day88 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
        <ModCovidHighlight>
          La India acumula otro día de máximos con un incremento de casi 10,000
          confirmados más.
        </ModCovidHighlight>
        <ModCovidHighlight>
          EE.UU supera los 110.000 fallecidos con covid-19, casi el doble que la
          guerra de Vietnam
        </ModCovidHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          En los últimos siete días se han diagnosticado en{" "}
          <strong>España</strong> 2.178 casos mediante pruebas PCR. En ese mismo
          periodo, 149 personas han requerido hospitalización (6,8% de los
          diagnósticos), de los que 12 han sido ingresos en la UCI, y se han
          producido 50 fallecimientos.
        </ModText>
        <ModText>
          El Gobierno ha aprobado hoy un{" "}
          <strong>
            Real Decreto-Ley de medidas urgentes de prevención, contención y
            coordinación
          </strong>{" "}
          para hacer frente a COVID-19 tras el fin de la vigencia del estado de
          alarma.
        </ModText>
        <ModAnimation svg="/images/anim/farmacia.svg" />
        <ModText>
          El texto, impulsado por el Ministerio de Sanidad y el de Transportes,
          Movilidad y Agenda Urbana, mantiene la{" "}
          <strong>obligatoriedad de las mascarillas</strong> (con multas de
          hasta 100 euros por incumplimiento), recoge indicaciones para la
          detección precoz y vigilancia epidemiológica del virus, y establece
          medidas de prevención y controles sanitarios.
        </ModText>

        <ModText>
          Por otra parte, también ha establecido la posiblidad de prorrogar
          hasta el 31 de diciembre el plazo para que asociaciones, sociedades
          civiles y mercantiles, cooperativas y fundaciones puedan adoptar
          acuerdos de forma no presencial.
        </ModText>
        <ModText>
          El Instituto de Salud Carlos III ha puesto en marcha un{" "}
          <strong>proyecto integral frente a COVID-19</strong> con la
          participación de 12 grupos de investigación. Entre los objetivos del
          proyecto, podemos encontrar:
        </ModText>
        <ModDotList>Establecer una red de laboratorios de virología</ModDotList>
        <ModDotList>Analizar las cadenas de transmisión del virus</ModDotList>
        <ModDotList>Estudiar y evaluar anticuerpos y biomarcadores</ModDotList>
        <ModDotList>Desarrollo tecnológico</ModDotList>
        <ModDotList>Un prototipo de vacuna</ModDotList>
        <ModText>
          El Consejo Superior de Deportes ha elaborado una{" "}
          <InlineLink link="https://www.csd.gob.es/sites/default/files/media/files/2020-06/FAQs%20Deporte%20Fase%203.pdf">
            guía de preguntas frecuentes
          </InlineLink>{" "}
          para deportistas profesionales cuyos territorios se encuentren en Fase
          3 de la desescalada.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day88;
